<template>
  <PflegeBaseComponentTemplate
    v-editable="props.blok"
    :actionsAlignment="props.blok.actionsAlignment"
    :backgroundColor="getPflegeColornameFromHex(props.blok.backgroundColor.value)"
    :data-uid="props.blok._uid"
    :heading="props.blok.heading"
    :headingTag="props.blok.headingTag"
    :subline="props.blok.subline"
    :sublineTag="props.blok.sublineTag"
    :textAlign="props.blok.textAlign"
    :headingClass="props.blok.headingClass"
    :sublineClass="props.blok.sublineClass"
  >
    <template v-if="props.blok.media" #media>
      <WPElementMedia
        v-for="asset in props.blok.media"
        :key="asset._uid"
        :blok="asset"
        :aspectRatio="aspectRatios.m"
      />
    </template>

    <template v-if="willRender(props.blok.content)" #default>
      <RichtextResolver :blok="props.blok.content" />
    </template>

    <template v-if="props.blok.actions" #actions>
      <WPElementButton v-for="button in props.blok.actions" :key="button._uid" :blok="button" />
    </template>
  </PflegeBaseComponentTemplate>
</template>

<script setup>
import { PflegeBaseComponentTemplate } from '@shared'

const props = defineProps({
  blok: {
    type: Object,
    required: true
  }
})

const { getPflegeColornameFromHex } = usePflegeUtils()
</script>
